<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-card-title
        tag="h1"
        class="m-0 py-1 px-2"
      >
        <b-row
          align-h="end"
          align-v="center"
        >
          <b-col cols="auto">
            <b-link
              class="text-indigo mr-1"
              @click="handlePrintOfferLinkClick"
            >
              <feather-icon
                v-b-tooltip.hover
                title="Descargar"
                icon="PrinterIcon"
                size="24"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_OFFERS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              :to="{ name: 'editOffer', params: { id: $route.params.id} }"
              class="d-inline-block px-50 text-indigo"
            >
              <feather-icon
                v-b-tooltip.hover
                title="Editar"
                icon="Edit2Icon"
                size="18"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_OFFERS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
            >
              <span
                class="d-inline-block px-50 text-danger"
                @click="handleDeleteOfferButtonClick()"
              >
                <feather-icon
                  v-b-tooltip.hover
                  title="Eliminar"
                  icon="TrashIcon"
                  size="18"
                />
              </span>
            </b-link>
          </b-col>
        </b-row>
        <b-row
          align-v="center"
          align-h="between"
        >
          <b-col cols="auto">
            <small>{{ offer ? offer.code : '' }}</small>
            <h2 class="font-weight-bold text-capitalize text-dark mb-0">
              {{ offer ? offer.name : '' }}
              <StatusSelect
                v-model="status"
                class="font-small-3 d-inline-block"
                :type="statusType"
                :clearable="false"
                @selection-change="handleStatusSelectionChange"
              />
            </h2>
            <small class="font-weight-bold">{{ offerDateText }}</small>
          </b-col>
          <b-col
            id="convert-offer-button"
            md="12"
            xl="3"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="isMaitenance && !jobVinculate"
              variant="primary"
              class="text-nowrap rounded-sm px-2 mr-1"
              @click="$router.push({ name: 'raterOffer', params: { id: offer.id } })"
            >
              Preventivos
            </b-button>
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              :disabled="convertOfferDisabled"
              @click="passOfferToJobModalVisible = true"
            >
              Pasar oferta a job
            </b-button>
            <b-tooltip
              v-if="status && status.name.toLowerCase() !== 'aceptada'"
              :disabled="!convertOfferDisabled"
              target="convert-offer-button"
            >
              La oferta no esta aceptada
            </b-tooltip>
        </b-col>
        <b-col v-if="jobVinculate" cols="12" class="d-flex justify-content-end pt-1">
          <div style="font-size: 15px;">
            <div  class="font-weight-bold d-flex justify-content-end pb-1">
              <span>{{ jobVinculate.titleType }}</span>
            </div>
            <b-link
              class="text-indigo"
              @click="$router.push({ name: 'viewJob', params: { id: jobVinculate.id } })"
            >
              <u>{{ jobVinculate.name }}</u>
            </b-link>
          </div>
        </b-col>
      </b-row>
      </b-card-title>
      <hr class="m-0">
      <ProfilePanel :profile="offerProfile" />
      <PassOfferToJobModal
        v-model="passOfferToJobModalVisible"
        :offer="offer"
       />
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OffersApi from '@/api/offers-api'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import PassOfferToJobModal from '@/components/offers/modal/PassOfferToJobModal.vue'
import { OFFER_STATUS_TYPE } from '@/api/status-api'

export default {
  name: 'OfferProfileCard',
  components: {
    StatusSelect,
    ProfilePanel,
    PassOfferToJobModal
  },
  data() {
    return {
      loading: false,
      statusType: OFFER_STATUS_TYPE,
      status: null,
      passOfferToJobModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('offer', {
      offer: 'getOffer',
      isMaitenance: 'getIsMaintenance',
    }),
    convertOfferDisabled() {
      const existJob = this.offer?.job != null
      const existAditionalJob = this.offer?.job_aditionals != null

      return existJob || existAditionalJob || (!this.status)  || this.status.name.toLowerCase() !== 'aceptada'
    },
    offerProfile() {
      return [
        { label: 'División', value: this.offer?.division?.name || 'Sin división' },
        { label: 'Usuario/Comercial', value: this.offer?.user?.name || 'Sin usuario/comercial' },
        { label: 'País', value: this.offer?.project?.country?.name || 'Sin país' },
        { label: 'Provincia', value: this.offer?.project?.province?.name || 'Sin provincia' },
        { label: 'Ciudad', value: this.offer?.project?.population || 'Sin ciudad' },
        { label: 'Cliente', value: this.offer?.client?.name || 'Sin cliente' },
        { label: 'Empresa', value: this.offer?.project?.name || 'Sin empresa' },
        { label: 'Forma de pago', value: this.offer?.type_payment?.name || 'Sin Forma de pago' },
        { label: 'Condiciones de la oferta', value: this.offer?.offer_conditions || 'Sin Condiciones' },
      ]
    },
    offerDateText() {
      if (!this.offer || !this.offer.offer_date) {
        return 'Sin fecha de oferta'
      }

      return new Date(this.offer.offer_date).toLocaleDateString()
    },
    jobVinculate () {
      const job = this.offer?.job ?? false
      const aditionalJob = this.offer?.job_aditionals ?? false
      if (job) {
        job.titleType = 'Oferta vinculada al job'
      }
      if(aditionalJob) {
        aditionalJob.titleType = 'Oferta vinculada en los adicionales del job'
      }
      return job || aditionalJob
    }
  },
  watch: {
    offer: {
      handler() {
        this.status = this.offer.status
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async handleStatusSelectionChange(status) {
      this.loading = true
      const offer = { ...this.offer, status }
      delete offer.documentation
      delete offer.lines_grouped

      try {
        const response = await OffersApi.edit(this.offer.id, offer)
        this.status = response.data.status
      } catch (_) {
        this.status = this.offer.status
      }
      this.loading = false
    },
    async handlePrintOfferLinkClick() {
      this.loading = true
      try {
        await OffersApi.printOffer(this.offer?.id)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteOfferButtonClick() {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${this.offer.name}`,
      })

      if (!response.isConfirmed) {
        return
      }

      try {
        this.setLoading(true)
        await OffersApi.delete(this.offer?.id)
        await this.$router.push({ name: 'offers' })
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  border: none;
}
</style>
