<template>
  <BaseCard
    title="datos de la empresa"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <ProjectProfileForm
          ref="project-profile-form"
          v-model="project"
        />
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <ProjectResponsibleForm
          ref="project-responsible-form"
          v-model="project"
        />
      </b-col>
    </b-row>
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ProjectResponsibleForm from '@/components/projects/form/ProjectResponsibleForm.vue'
import ProjectProfileForm from '@/components/projects/form/ProjectProfileForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, ProjectProfileForm, ProjectResponsibleForm },
  data() {
    return {
      loading: false,
      project: {},
    }
  },
  computed: {
    profileForm() {
      return this.$refs['project-profile-form']
    },
    responsibleForm() {
      return this.$refs['project-responsible-form']
    },
  },
  mounted() {
    this.loadProject()
  },
  methods: {
    async loadProject() {
      this.loading = true
      try {
        const response = await ApiRestService.get(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS)
        this.project = response.data
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const profileValid = await this.profileForm.validateForm()
      const responsibleValid = await this.responsibleForm.validateForm()

      if (!profileValid || !responsibleValid) {
        return
      }

      this.loading = true
      try {
        const { documentation, removedFiles } = this.profileForm.getDocumentationData()
        const project = { ...this.project, documentation }
        await Promise.all([
          ApiRestService.edit(this.$route.params.id, project, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS),
          ApiRestService.deleteFiles(this.$route.params.id, removedFiles, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS),
        ])
        await this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
