<template>
  <b-modal
    v-model="model"
    size="md"
    hide-footer
    centered
  >
    <h1 class="font-large-1 text-center pt-2">
      Nuevo consumo
    </h1>
    <validation-observer ref="job-expected-cost-line-order-form">
      <b-form
        class="py-2"
        @submit.prevent
      >
        <b-row align-h="center">
          <b-col
            sm="12"
            lg="10"
          >
            <validation-provider
              v-slot="validationContext"
              name="Proveedor"
              rules="required"
            >
              <b-form-group
                label="Proveedor"
                label-for="provider"
              >
                <BaseSelect
                  id="provider"
                  v-model="provider"
                  :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <b-row align-h="center">
        <b-col cols="auto">
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm px-2"
            @click="handleAcceptButtonClick"
          >
            Aceptar
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'JobExpectedCostLineOrderModal',
  components: { BaseSelect },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      provider: null,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async handleAcceptButtonClick() {
      const valid = await this.$refs['job-expected-cost-line-order-form'].validate()
      if (!valid) {
        return
      }

      this.$emit('input', false)
      this.$emit('create-order', this.provider)
    },
  },
}
</script>

<style scoped>
</style>
