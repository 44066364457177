<template>
  <b-card
    no-body
    :class="'mb-1 ' + bgClass"
  >
    <b-row
      align-h="between"
      align-v="center"
      :class="'p-2 ' + (collapseEnabled ? 'cursor-pointer' : '')"
      @click="collapse = !collapse"
    >
      <b-col
        cols="auto"
        class="text-gray-50 font-weight-bold name-width"
      >
        <span class="text-uppercase">{{ title }}</span>
      </b-col>
      <b-col class="text-right">
        (<span class="font-weight-bold">{{ ordersCount | numberToLocalString }}</span> pedidos realizados)
      </b-col>
      <b-col class="text-uppercase text-right">
        <div>total consumido</div>
        <div class="font-weight-bold">
          {{ totalGrossOrder | numberToLocalString }} €
        </div>
      </b-col>
      <b-col class="text-uppercase text-right">
        <div>facturado</div>
        <div class="font-weight-bold">
          {{ totalBilling | numberToLocalString }} €
        </div>
      </b-col>
      <b-col class="text-uppercase text-right">
        <div>pendiente de facturar</div>
        <div class="font-weight-bold">
          {{ totalPendingBilling | numberToLocalString }} €
        </div>
      </b-col>
      <b-col
        cols="auto"
        class="collapse-col-width"
      >
        <template v-if="collapseEnabled">
          <FeatherIcon
            v-if="!collapse"
            icon="ChevronDownIcon"
            class="text-primary"
          />
          <FeatherIcon
            v-else
            icon="ChevronUpIcon"
            class="text-primary"
          />
        </template>
      </b-col>
    </b-row>
    <b-collapse v-model="collapse">
      <slot />
    </b-collapse>
  </b-card>
</template>

<script>

export default {
  name: 'JobOrdersCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    totals: {
      type: Object || Array,
      default: () => ({
        count: 0,
        total_gross_order: 0,
        total_billing: 0,
        total_pending_billing: 0,
      }),
    },
    collapseEnabled: {
      type: Boolean,
      default: false,
    },
    bgClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      collapse: !this.collapseEnabled,
    }
  },
  computed: {
    ordersCount() {
      return this.totals.count || 0
    },
    totalGrossOrder() {
      return this.totals.total_gross_order || 0
    },
    totalBilling() {
      return this.totals.total_billing || 0
    },
    totalPendingBilling() {
      return this.totals.total_pending_billing || 0
    },
  },
}
</script>

<style scoped>
.name-width {
  width: 205px;
}

.collapse-col-width {
  width: 75px;
}
</style>
