<template>
  <b-card
    no-body
    class="rounded-top"
  >
  <template v-if="economicAdvanceItems.length">
    <div class="font-weight-bold text-uppercase p-2">
      Avance económico
    </div>
    <b-table
      responsive
      :fields="fields"
      :items="economicAdvanceItems"
      :bordered="true"
      :busy="loading"
      class="mb-0 rounded-bottom"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ 'min-width': field.key === 'name' ? '250px' : '147px' }"
        >
      </template>
      <template #thead-top>
        <b-tr class="text-center text-uppercase">
          <b-th class="bg-white" />
          <b-th
            colspan="2"
            class="bg-white"
          >
            contratos / costes
          </b-th>
          <b-th
            colspan="4"
            class="bg-white"
          >
            previsión costes
          </b-th>
          <b-th
            colspan="3"
            class="bg-white"
          >
            real costes
          </b-th>
        </b-tr>
      </template>
      <template #cell(name)="data">
        <span class="font-weight-bold text-uppercase font-small-1 text-dark-gray">
          {{ data.item.name }}
        </span>
      </template>
      <template #cell()="data">
        {{ formatValue(data.value) }}
      </template>
      <template #custom-foot>
        <b-tr class="bg-action-blue">
          <b-td class="px-2 py-1 font-weight-bold text-uppercase font-small-1">
            Total
          </b-td>
          <b-td
            v-for="field in fieldsWithoutNameField"
            :key="field.key"
            class="px-2 py-1 text-right"
          >
            <span v-if="field.totalKey == 'total_expected_live_prevision'" class="font-weight-bold">{{ formatValue(totalRowData[field.totalKey]) }}</span>
            <span v-else>{{ formatValue(totalRowData[field.totalKey]) }}</span>
          </b-td>
        </b-tr>
        <b-tr class="font-small-1 text-center text-uppercase font-weight-bold">
          <b-td />
          <b-td colspan="3">
            margen teórico {{ margins.theoretical_margin || 0 | numberToLocalString }} %
          </b-td>
          <b-td colspan="3">
            margen previsión {{ margins.prevision_margin || 0 | numberToLocalString }} %
          </b-td>
          <b-td
            colspan="3"
            :class="{'font-weight-bolder': jobClosed, 'font-italic': jobOpen }"
          >
            margen real {{ margins.real_margin || 0 | numberToLocalString }} %
          </b-td>
        </b-tr>
        <b-tr class="font-small-1 text-center text-uppercase font-weight-bold bg-action-blue">
          <b-td colspan="10">
            <span class="px-1">margen objetivo:</span>
            <b-input-group class="input-group-merge d-inline-flex objective-margin-input">
              <b-form-input
                v-model="margins.target_margin"
                type="number"
                :disabled="readonly"
                @change="updateJobObjectiveMargin"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </b-td>
        </b-tr>
      </template>
      <template #table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>
  </template>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import JobsApi from '@/api/jobs-api'

export default {
  name: 'JobEconomicAdvanceTable',
  props: {
    loading: Boolean,
  },
  data() {
    return {
      fields: [
        {
          label: 'PARTIDAS',
          key: 'name',
          sortable: false,
          thClass: 'font-small-1 px-1 py-1',
          tdClass: 'bg-table-headings px-1 py-1',
          totalKey: 'name',
        },
        {
          label: 'VALOR VENTA',
          key: 'contract_sale',
          sortable: false,
          tdClass: 'text-right bg-light-green',
          thClass: 'text-right font-small-1',
          totalKey: 'contract_total_sale',
        },
        {
          label: 'COSTE TEÓRICO',
          key: 'contract_cost',
          sortable: false,
          tdClass: 'text-right bg-light-green',
          thClass: 'text-right font-small-1 ',
          totalKey: 'contract_total_cost',
        },
        {
          label: 'PREV. INICIAL',
          key: 'expected_costs_prevision',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right font-small-1',
          totalKey: 'total_expected_costs_prevision',
        },
        {
          label: 'PREV. VIVA',
          key: 'expected_costs_live_prevision',
          sortable: false,
          tdClass: 'text-right bg-light-yellow',
          thClass: 'text-right font-small-1',
          totalKey: 'total_expected_live_prevision',
        },
        {
          label: 'CONSUMIDO',
          key: 'expected_costs_order',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right font-small-1',
          totalKey: 'total_expected_costs_order',
        },
        {
          label: 'PENDIENTE',
          key: 'expected_costs_pendent',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right font-small-1',
          totalKey: 'total_expected_costs_pendent',
        },
        {
          label: 'T. PEDIDO COMPROMETIDO',
          key: 'commited_order',
          sortable: false,
          tdClass: 'text-right bg-light-orange',
          thClass: 'text-right font-small-1',
          totalKey: 'total_commited_order',
        },
        {
          label: 'T. FACTURAS CONTABILIZADAS',
          key: 'posted_invoices',
          sortable: false,
          tdClass: 'text-right bg-light-orange',
          thClass: 'text-right font-small-1',
          totalKey: 'total_posted_invoices',
        },
        {
          label: 'T. COSTES - PEDIDOS',
          key: 'ordered_costs',
          sortable: false,
          tdClass: 'text-right bg-light-yellow',
          thClass: 'text-right font-small-1',
          totalKey: 'total_ordered_costs',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
      economicState: 'getJobEconomicState',
      readonly: 'getIsReadOnlyMode',
    }),
    jobOpen() {
      return this.job?.status?.name === 'Abierto'
    },
    jobClosed() {
      return this.job?.status?.name === 'Cerrado'
    },
    economicAdvance() {
      return this.economicState?.economic_advance
    },
    margins() {
      return this.economicState?.margins || {}
    },
    economicAdvanceItems() {
      if (!this.economicAdvance) {
        return []
      }

      const items = [...this.economicAdvance]
      items.pop()

      return items
    },
    totalRowData() {
      if (!this.economicAdvance) {
        return {}
      }

      return this.economicAdvance[this.economicAdvance.length - 1]
    },
    fieldsWithoutNameField() {
      return this.fields.filter(field => field.key !== 'name')
    },
  },
  methods: {
    formatValue(value) {
      return value ? `${this.$options.filters.numberToLocalString(value)} €` : '-'
    },
    updateJobObjectiveMargin(margin) {
      JobsApi.edit(this.job.id, { target_margin: margin })
    },
  },
}
</script>

<style scoped>
.objective-margin-input {
  width: 100px;
}
</style>
