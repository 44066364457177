<template>
  <BaseCard
    :footer-visible="false"
    actions-visible
    :resource="$data.$constants.RESOURCES.RESOURCE_PROJECTS"
    @edit-icon-click="$router.push({ name: 'editProject', params: { id: $route.params.id } })"
    @delete-confirmation="handleDeleteConfirmation"
  >
    <template #title>
      <div>
        <small>Empresa</small>
        <div class="font-weight-bold text-capitalize text-dark h3 mb-0">
          {{ project ? project.name : '' }}
        </div>
        <small class="text-transform-none">
          Fecha contratación:
          <span class="font-weight-bold">{{ (project ? project.date_of_hiring : null ) | formatDate }}</span>
        </small>
      </div>
    </template>
    <ProfilePanel :profile="projectProfile" />
    <ProfilePanel
      title="responsable"
      :profile="projectResponsibleProfile"
    />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import { mapActions } from 'vuex'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'

export default {
  name: 'ProjectProfileCard',
  components: { ProfilePanel, BaseCard },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  computed: {
    projectProfile() {
      if (!this.project) {
        return []
      }

      return [
        { label: 'Dirección de la empresa', value: this.project?.address || 'Sin dirección' },
        { label: 'País', value: this.project?.country?.name || 'Sin país' },
        { label: 'Provincia', value: this.project?.province?.name || 'Sin provincia' },
        { label: 'Población', value: this.project?.population || 'Sin población' },
        { label: 'Código postal', value: this.project?.post_code || 'Sin código postal' },
      ]
    },
    projectResponsibleProfile() {
      if (!this.project) {
        return []
      }

      return [
        { label: 'Nombre', value: this.project?.resp_name || 'Sin nombre' },
        { label: 'Email', value: this.project?.resp_email || 'Sin email' },
        { label: 'Teléfono', value: this.project?.resp_phone || 'Sin teléfono' },
        { label: 'Puesto', value: this.project?.resp_position || 'Sin puesto' },
      ]
    },
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async handleDeleteConfirmation() {
      if (!this.project) {
        return
      }

      this.setLoading(true)
      try {
        await ApiRestService.delete(this.project.id, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS)
        await this.$router.push('/projects')
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
