<template>
  <validation-observer ref="create-offer">
    <b-form class="p-2" @submit.prevent>
      <b-row>
        <b-col sm="12" md="6">
          <b-row>
            <b-col v-if="!hideCode" sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Código"
                rules="required"
              >
                <b-form-group label="Código" label-for="offer-code">
                  <b-form-input
                    id="offer-code"
                    ref="offer-code-input"
                    :value="model.code"
                    placeholder="Código"
                    :state="
                      $getValidationState(validationContext) && codeInputState
                    "
                    @input="update('code', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="División"
                rules="required"
              >
                <b-form-group label="División" label-for="offer-division">
                  <BaseSelect
                    id="offer-division"
                    :value="model.division"
                    :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
                    http-method="get"
                    @input="update('division', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Fecha"
                rules="required"
              >
                <b-form-group label="Fecha">
                  <b-form-datepicker
                    :value="model.offer_date"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    size="sm"
                    reset-button
                    :hide-header="true"
                    placeholder="Fecha"
                    :label-help="null"
                    :state="$getValidationState(validationContext)"
                    @input="update('offer_date', $event)"
                    start-weekday="1"
                  />
                </b-form-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Nombre"
                rules="required"
              >
                <b-form-group label="Nombre" label-for="offer-name">
                  <b-form-input
                    :value="model.name"
                    name="offer-name"
                    placeholder="Nombre"
                    :state="$getValidationState(validationContext)"
                    @input="update('name', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Cliente"
                rules="required"
              >
                <b-form-group label="Cliente" label-for="offer-client">
                  <ClientSelect
                    id="offer-client"
                    :value="model.client"
                    @input="updateClient($event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Forma de pago"
                label-for="client-type-payment"
              >
                <BaseSelect
                  id="client-type-payment"
                  :value="model.type_payment"
                  :resource="$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS"
                  @input="update('type_payment', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Empresa" label-for="offer-project">
                <ProjectSelect
                  id="offer-project"
                  :value="model.project"
                  @input="update('project', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Estado"
                rules="required"
              >
                <b-form-group label="Estado" label-for="offer-status">
                  <StatusSelect
                    :value="model.status"
                    :type="statusType"
                    @input="update('status', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Usuario"
                rules="required"
              >
                <b-form-group label="Usuario" label-for="offer-user">
                  <BaseSelect
                    id="offer-user"
                    :value="model.user"
                    :resource="$data.$constants.RESOURCES.RESOURCE_USERS"
                    @input="update('user', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="6">
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Condiciones de la oferta"
                label-for="offer-conditions"
              >
                <b-form-textarea
                  :value="model.offer_conditions"
                  name="offer-conditions"
                  placeholder="Condiciones de la oferta"
                  rows="4"
                  max-rows="4"
                  @input="update('offer_conditions', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Observaciones"
                label-for="offer-observations"
              >
                <b-form-textarea
                  :value="model.observations"
                  name="offer-observations"
                  placeholder="Observaciones"
                  rows="4"
                  max-rows="4"
                  @input="update('observations', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Adjuntar Archivos">
                <FileDropZone
                  ref="documentation-input"
                  :files="files"
                  @removed-file="removedFiles.push($event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { OFFER_STATUS_TYPE } from "@/api/status-api";
import FormMixin from "@/mixins/formMixin";
import FileDropZone from "@/components/ui/file/FileDropZone.vue";
import StatusSelect from "@/components/status/select/StatusSelect.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import ClientSelect from "@/components/clients/select/ClientSelect.vue";
import ProjectSelect from "@/components/projects/select/ProjectSelect.vue";
import ApiRestService from '@/api/base-api'

export default {
  name: "OfferForm",
  components: {
    ProjectSelect,
    ClientSelect,
    BaseSelect,
    StatusSelect,
    FileDropZone,
  },
  mixins: [FormMixin],
  props: {
    hideCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusType: OFFER_STATUS_TYPE,
      removedFiles: [],
      codeInputState: true,
      clientCreationModalVisible: false,
    };
  },
  computed: {
    files() {
      return this.value?.documentation || [];
    },
  },
  methods: {
    async updateClient(client) {
      this.update('client', client)
      const response = await ApiRestService.get(client.id, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
      this.update('type_payment', response.data.type_payment)
      console.log(response)
    },
    focusCodeError() {
      this.$refs["offer-code-input"].focus();
      this.codeInputState = false;
    },
    validateForm() {
      return this.$refs["create-offer"].validate();
    },
    getDocumentationData() {
      return {
        documentation: this.$refs["documentation-input"].getFiles(),
        removedFiles: this.removedFiles,
      };
    },
  },
};
</script>

<style scoped></style>
