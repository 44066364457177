<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group
          label="Empresa"
          label-for="project-name"
        >
          <b-form-input
            id="project-name"
            :value="model.name"
            name="name"
            placeholder="Empresa"
            @input="update('name', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="País"
          label-for="project-country"
        >
          <BaseSelect
            id="project-country"
            :value="model.country"
            http-method="get"
            :resource="$data.$constants.RESOURCES.RESOURCE_COUNTRIES"
            @input="handleCountryInput"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Provincia"
          label-for="project-province"
        >
          <BaseSelect
            id="project-province"
            ref="project-province-select"
            :value="model.province"
            :resource="$data.$constants.RESOURCES.RESOURCE_PROVINCES"
            @input="update('province', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Cliente"
          label-for="project-client"
        >
          <BaseSelect
            id="project-client"
            :value="model.client"
            :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
            @input="update('client', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'ProjectListFiltersForm',
  components: { BaseSelect },
  mixins: [FormMixin],
  methods: {
    handleCountryInput(country) {
      this.update('country', country)
      this.$refs['project-province-select'].loadData({ country })
    },
  },
}
</script>

<style scoped>

</style>
