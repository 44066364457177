<template>
  <BaseCard
    ref="base-card"
    :loading="loading"
    :title-visible="false"
    :footer-visible="false"
  >
    <template #title-free>
      <b-row class="m-2" align-v="center">
        <b-col
          cols="auto"
          class="job-name-container"
        >
          <small>{{ job ? job.code : '' }}</small>
          <h2
            class="font-weight-bold text-capitalize text-dark mb-0"
          >
            {{ job ? job.name : '' }}
          </h2>
          <small class="font-weight-bold">{{ jobDateText }}</small>
        </b-col>
        <b-col
          cols="auto"
          class="text-transform-none font-weight-normal"
        >
          <StatusBadge
            class="font-small-3"
            :status="status"
          />
        </b-col>
        <b-col v-if="isMaintenance" cols="auto" class="d-flex align-items-center">
          <b-form-group
            label="Contrato de mantenimiento"
            label-for="certification-client"
          >
            <BaseSelect
              label="name"
              :clearable="false"
              :placeholder="'Contrato de mantenimiento'"
              :value="contractSelected.contract"
              :http-body="{ job_id: job.id }"
              :resource="$data.$constants.RESOURCES.RESOURCE_JOBS_CONTRACTS"
              @input="handleContractSelected($event)"
            />
          </b-form-group>
          <b-link v-if="contractSelected.contract" @click="showRangeContractModal = true" class="text-indigo">
            <feather-icon
              class="ml-1"
              title="Editar"
              icon="Edit2Icon"
              size="18"
            />
          </b-link>
        </b-col>
        <b-col v-if="!isMaintenance" cols="auto">
          <div v-if="!readonly" class="d-inline-block" >
            <b-form-checkbox
              class="mb-1"
              :checked="signedContract"
              value="1"
              :unchecked-value="0"
              @change="handleSignedContractCheckboxChange"
            >
              Contrato firmado
            </b-form-checkbox>
            <div class="d-flex align-items-center">
              <BaseFileInput
                v-if="!contractName"
                ref="contract-file-input"
                class="d-inline"
                :multiple="false"
                @change="handleContractInputChange"
              >
                <b-link
                  class="text-indigo d-flex align-items-center"
                >
                <feather-icon
                  icon="UploadIcon"
                  size="19"
                  style="margin-right: 8px; margin-bottom: 5px;"
                />
                <span>
                  Subir contrato
                </span>
              </b-link>
              </BaseFileInput>
              <span
                v-if="contractVisible"
                class="d-flex align-items-center"
              >
                <div class="contract-container">
                  <b-link
                    size="sm"
                    class="text-decoration-none text-indigo"
                    @click="handleDownloadContractLinkClick"
                  >
                    <u>{{ contractName }}</u>
                  </b-link>
                </div>
                <b-link @click="handleDeleteContractButtonClick">
                  <span
                    class="d-inline-block text-danger"
                  >
                    <feather-icon
                      v-b-tooltip.hover
                      title="Eliminar"
                      icon="TrashIcon"
                      size="18"
                    />
                  </span>
                </b-link>
              </span>
            </div>
          </div>
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <div class="d-flex justify-content-end">
            <b-link
              v-if="!readonly || isSuperAdmin"
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_JOBS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              :to="{ name: 'editJob', params: { id: $route.params.id} }"
               class="d-inline-block  text-indigo btn btn-primary btn-sm rounded-sm me-1"
            >
              <feather-icon
                v-b-tooltip.hover
                title="Editar job"
                icon="Edit2Icon"
                size="18"
              />  <span>Editar job</span>
            </b-link>
            <b-link
              v-if="!readonly && isSuperAdmin"
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_JOBS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              @click="handleDeleteButtonClick()"
            >
              <span
                class="d-inline-block text-danger btn btn-danger btn-sm rounded-sm"
              >
                <feather-icon
                  v-b-tooltip.hover
                  title="Eliminar job"
                  icon="TrashIcon"
                  size="18"
                />  <span>Eliminar job</span>
              </span>
            </b-link>
          </div>
          <template v-if="daysEndActiveContract ">
            <div class="d-flex justify-content-end">
              <b-button
                v-if="showRenewButton"
                variant="primary"
                class="rounded-sm px-2 my-1"
                @click="showRenewContractModal = true"
              >
                Renovar
              </b-button>
            </div>
            <span v-if="showRenewMessage" class="text-danger">
              {{ showRenewMessage || '' }}
            </span>
          </template>
          <template v-if="!isMaintenance">
            <div
              v-if="initialCostPreviewDocument"
              class="text-transform-none font-weight-normal p-1"
            >
              <div>
                <b-link
                  class="text-decoration-none text-indigo font-small-3"
                  @click="handleInitialCostPreviewDownload"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="19"
                    class="mr-25"
                  />
                  <u>{{ initialCostPreviewDocument ? initialCostPreviewDocument.name : '' }}</u>
                </b-link>
              </div>
              <div>
                <label>
                  {{ initialCostPreviewDocumentUser ? initialCostPreviewDocumentUser.name : '' }}
                  -
                  {{ initialCostPreviewDocumentDate | formatDate }}
                </label>
              </div>
            </div>
            <div v-else>
              <b-button
                v-if="!readonly"
                variant="primary"
                class="rounded-sm px-2 mt-1"
                @click="handleInitialCostPreviewButtonClick"
              >
                Generar previsión costes inicial
              </b-button>
            </div>
          </template>
        </b-col>
      </b-row>
    </template>
    <ProfilePanel :profile="jobProfile" />
    <ContactsPanel
      v-if="job && job.contacts.length > 0"
      :contacts="job.contacts"
    />
    <JobRenewContractModal v-model="showRenewContractModal" />
    <JobRangeContractModal v-model="showRangeContractModal" :jobId="jobId"/>
  </BaseCard>
</template>
<script>
import JobsApi from '@/api/jobs-api'
import ApiRestService from '@/api/base-api'
import { mapActions, mapGetters } from 'vuex'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import ContactsPanel from '@/components/ui/panel/ContactsPanel.vue'
import BaseFileInput from '@/components/ui/file/BaseFileInput.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import JobRenewContractModal from '@/components/jobs/modal/JobRenewContractModal.vue'
import JobRangeContractModal from '@/components/jobs/modal/JobRangeContractModal.vue'


export default {
  name: 'JobProfileCard',
  components: {
    BaseCard,
    BaseFileInput,
    ProfilePanel,
    StatusBadge,
    ContactsPanel,
    BaseSelect,
    JobRenewContractModal,
    JobRangeContractModal
  },
  data() {
    return {
      loading: false,
      signedContractCheckboxTimeout: null,
      showRenewContractModal: false,
      showRangeContractModal: false,
    }
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
      isMaintenance: 'getIsMaintenance',
      readonly: 'getIsReadOnlyMode',
      daysEndActiveContract: 'getDaysEndActiveContract',
    }),
    jobId() {
      return this.job?.id
    },
    jobProfile() {
      return [
        { label: 'Project Manager', value: this.job?.user?.name || 'Sin Project Manager' },
        { label: 'División', value: this.job?.division?.name || 'Sin división' },
        { label: 'Cliente', value: this.job?.client?.name || 'Sin cliente' },
        { label: 'Empresa', value: this.job?.project?.name || 'Sin empresa' },
        { label: 'Provincia', value: this.job?.project?.province?.name || '' },
        {
          label: 'Referencia cliente',
          value: (this.isMaintenance ? this.contractSelected?.contract?.ref_client : this.job?.ref_client) || 'Sin referencia cliente'
        },
      ]
    },
    jobDateText() {
      if (!this.job || !this.job.job_date) {
        return 'Sin fecha de job'
      }

      return new Date(this.job.job_date).toLocaleDateString()
    },
    signedContract() {
      return this.job?.signed_contract
    },
    status() {
      return this.job?.status?.name || ''
    },
    contractVisible() {
      return this.job?.contract?.length > 0
    },
    contractName() {
      return this.contractVisible ? this.job.contract[0].name : ''
    },
    initialCostPreviewDocument() {
      const document = this.job?.start_document?.document || null
      if (!document || document.length === 0) {
        return null
      }

      return document
    },
    initialCostPreviewDocumentUser() {
      return this.job?.start_document?.user
    },
    initialCostPreviewDocumentDate() {
      return this.job?.start_document?.date
    },
    isSuperAdmin () {
      return JSON.parse(localStorage.getItem('userData')).roles[0].name === 'super_admin'
    },
    contractSelected () {
      return this.$store.getters['job/getContractSelected'](this.job.id)
    },
    showRenewButton() {
      return this.daysEndActiveContract <= 10 && this.isMaintenance;
    },
    showRenewMessage() {
      if (this.isMaintenance) {
        let message = null
        if (this.daysEndActiveContract <= 10) {
          message = `Quedan ${this.daysEndActiveContract} días hasta finalizar el contrato`
        }
        if (this.daysEndActiveContract <= 0) {
          message = "No se dispone de un contrato de mantenimiento activo"
        }
        return message
      }
    },
  },
  methods: {
    ...mapActions('job', ['setJob', 'loadJob']),
    async handleDeleteButtonClick() {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${this.job.name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      try {
        this.loading = true
        await JobsApi.delete(this.job.id)
        await this.$router.push({ name: 'jobs' })
      } finally {
        this.loading = false
      }
    },
    async handleContractInputChange(contract) {
      this.loading = true
      try {
        const signed_contract = contract && contract.length > 0 && contract[0] ? 1 : 0
        const jobToUpdate = { ...this.job, contract, signed_contract }
        delete jobToUpdate.documentation
        const response = await JobsApi.edit(this.job.id, jobToUpdate)
        this.setJob(response.data)
      } finally {
        this.loading = false
      }
    },
    handleSignedContractCheckboxChange(signed_contract) {
      if (this.signedContractCheckboxTimeout) {
        clearTimeout(this.signedContractCheckboxTimeout)
      }

      this.signedContractCheckboxTimeout = setTimeout(() => {
        const jobToUpdate = { ...this.job, signed_contract }
        delete jobToUpdate.contract
        delete jobToUpdate.documentation
        JobsApi.edit(this.job.id, jobToUpdate)
      }, 500)
    },
    async handleDeleteContractButtonClick() {
      if (!this.contractVisible) {
        return
      }

      this.loading = true
      try {
        await JobsApi.deleteContract(this.job.id, this.job.contract[0].id)
        const newJob = { ...this.job }
        newJob.contract.pop()
        this.setJob(newJob)
        this.$refs['contract-file-input'].cleatFileInput()
      } finally {
        this.loading = false
      }
    },
    async handleDownloadContractLinkClick() {
      this.loading = true
      try {
        await ApiRestService.downloadArchive(this.job.contract[0].id, this.job.contract[0].name)
      } finally {
        this.loading = false
      }
    },
    async handleInitialCostPreviewButtonClick() {
      this.loading = true
      try {
        await JobsApi.printJob(this.job?.id)
        await this.loadJob(this.job?.id)
      } finally {
        this.loading = false
      }
    },
    async handleInitialCostPreviewDownload() {
      this.loading = true
      try {
        await ApiRestService.downloadArchive(this.initialCostPreviewDocument.id, this.initialCostPreviewDocument.name)
      } finally {
        this.loading = false
      }
    },
    setCollapse(collapse) {
      this.$refs['base-card'].setCollapse(collapse)
    },
    handleContractSelected (contract) {
      this.loading = true
      const value = { job_id: this.job.id, contract: contract }
      this.$store.dispatch('job/setContractSelected', value)
      window.location.reload();
    }
  },
}
</script>

<style scoped>
.contract-container {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.job-name-container {
  max-width: 90%;
}
.me-1{
  margin-right: 1rem;
}
</style>
