<template>
  <b-table
    hover
    responsive
    fixed
    class="light-yellow-table-headings"
    :items="orders"
    :fields="fields"
    :no-local-sorting="true"
    @row-clicked="$router.push({ name: 'viewOrder', params: { id: $event.id } })"
  >
    <template #cell(pm_comments)="{ value }">
      {{ value }}
    </template>
    <template #cell(order_date)="{ value }">
      {{ value | formatDate }}
    </template>
    <template #cell(number)="{ value }">
      {{ value }}
    </template>
    <template #cell(provider)="{ value }">
      {{ value ? value.name : '' }}
    </template>
    <template #cell(total_gross_cy)="{ value }">
      {{ value | numberToLocalString }} €
    </template>
    <template #cell(status)="{ value }">
      <StatusBadge :status="value.name" />
    </template>
    <template #cell(actions)="data">
      <b-link
        class="d-inline-block text-danger pr-1"
        @click="handleDelete(data.item)"
      >
        <feather-icon
          v-b-tooltip.hover 
          title="Eliminar"
          icon="TrashIcon"
          size="18"
        />
      </b-link>
    </template>
  </b-table>
</template>
<script>
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import OrdersApi from '@/api/orders-api'

export default {
  name: 'JobExpectedCostLineOrdersTable',
  components: { StatusBadge },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'COMENTARIOS PM',
          sortable: false,
          key: 'pm_comments',
        },
        {
          label: 'FECHA PEDIDO',
          sortable: false,
          key: 'order_date',
        },
        {
          label: 'Nº PEDIDO',
          sortable: false,
          key: 'number',
        },
        {
          label: 'PROVEEDOR',
          sortable: false,
          key: 'provider',
        },
        {
          label: 'IMPORTE',
          sortable: false,
          key: 'total_gross_cy',
        },
        {
          label: 'ESTADO',
          sortable: false,
          key: 'status',
        },
        {
          label: '',
          sortable: false,
          key: 'actions',
        },
      ],
    }
  },
  methods:{
    async handleDelete(order) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro Nº${order.number}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      try {
        await OrdersApi.delete(order.id)
        this.$emit('uploadInfo')
      } catch {}
    },
  }
}
</script>

<style scoped>
</style>
