<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-card-title class="m-0 py-1 px-2">
        <b-row align-h="end" align-v="center">
          <b-col cols="auto">
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
                resourceAction:
                  $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
              }"
              :to="{
                name: 'editPurchaseInvoice',
                params: { id: $route.params.id },
              }"
              class="d-inline-block px-50 text-indigo"
            >
              <feather-icon
                v-b-tooltip.hover
                title="Editar"
                icon="Edit2Icon"
                size="18"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
                resourceAction:
                  $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
              }"
            >
              <span
                class="d-inline-block px-50 text-danger"
                @click="handleDeleteSaleInvoiceButtonClick()"
              >
                <feather-icon
                  v-b-tooltip.hover
                  title="Eliminar"
                  icon="TrashIcon"
                  size="18"
                />
              </span>
            </b-link>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="auto">
            <small class="text-dark font-weight-bold">
              Nº Factura proveedor
              {{ purchaseInvoice ? purchaseInvoice.provider_code : "" }}
            </small>
            <div class="font-weight-bold text-capitalize text-dark mb-0">
              {{
                purchaseInvoice && purchaseInvoice.provider
                  ? purchaseInvoice.provider.name
                  : ""
              }}
              <StatusBadge
                class="font-small-3 ml-2"
                :status="purchaseInvoice ? purchaseInvoice.status.name : ''"
              />
              <StatusBadge
                v-if="purchaseInvoice &&
                  purchaseInvoice.send_payment_document &&
                  purchaseInvoice.send_payment_document === 1
                "
                class="font-small-3 ml-1"
                :status="'Envio doc. pago'"
                :text="'Envio doc. pago'"
                :variant="'light-success'"
              />
            </div>
            <div>
              <small class="font-weight-bold">
                  {{ purchaseInvoice ? purchaseInvoice.provider.cif : "" }}
              </small>
            </div>
            <small>
              <span class="font-weight-bold">
                {{ purchaseInvoiceDate }}
              </span>
              vence
              <span>
                {{ purchaseInvoiceDueDate }}
              </span>
            </small>
          </b-col>
        </b-row>
      </b-card-title>
      <hr class="m-0" />
      <ProfilePanel :profile="purchaseInvoiceProfile" />
      <ContactsPanel
        v-if="purchaseInvoice && purchaseInvoice.contact"
        :title="'Contacto facturación'"
        :contacts="[purchaseInvoice.contact]"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PurchaseInvoicesApi from "@/api/purchase-invoices-api";
import ProfilePanel from "@/components/ui/panel/ProfilePanel.vue";
import ContactsPanel from '@/components/ui/panel/ContactsPanel.vue'
import StatusBadge from "@/components/status/badge/StatusBadge.vue";

export default {
  name: "PurchaseInvoicesProfileCard",
  components: {
    StatusBadge,
    ProfilePanel,
    ContactsPanel
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("purchaseInvoice", {
      purchaseInvoice: "getPurchaseInvoice",
      tab: "getTabSelected",
    }),
    origin() {
      return this.tab === 0 ? "invoice" : "fra";
    },
    purchaseInvoiceDate() {
      return this.purchaseInvoice?.invoice_date
        ? new Date(this.purchaseInvoice.invoice_date).toLocaleDateString()
        : "";
    },
    purchaseInvoiceDueDate() {
      return this.purchaseInvoice?.expiration_date
        ? new Date(this.purchaseInvoice.expiration_date).toLocaleDateString()
        : "";
    },
    purchaseInvoiceRetentionExpiration() {
      return this.purchaseInvoice?.retention_expiration
        ? new Date(
            this.purchaseInvoice.retention_expiration
          ).toLocaleDateString()
        : "";
    },
    purchaseInvoiceProfile() {
      let info = [
        {
          label: "Número de factura de provedor",
          value: this.purchaseInvoice?.provider_code || "Sin Numero",
        },
        {
          label: "Empresa",
          value: this.purchaseInvoice?.job?.project?.name || "Sin empresa",
        },
        { label: "Retención", value: this.retentionText },
      ];
      if (this.origin === "fra") {
        info.push({
          label: "Partida",
          value: this.purchaseInvoice?.accounting_item?.name || "Sin partida",
        });
      } else {
        info.push(
          {
            label: "Pedido",
            value: this.purchaseInvoice?.order?.number || "Sin pedido",
          },
          {
            label: "División",
            value: this.purchaseInvoice?.division?.name || "Sin división",
          },
          {
            label: "Job",
            value: this.purchaseInvoice?.job?.name || "Sin Job",
            link: !!this.purchaseInvoice?.job,
            id: this.purchaseInvoice?.job?.id,
            aditionalInfo: this.purchaseInvoice?.job_contract?.name,
            routeName: 'viewJob'
          },
          {
            label: "Categoría",
            value:
              this.purchaseInvoice?.category_group?.name || "Sin categoría",
          },
          {
            label: "Numero",
            value:
              this.purchaseInvoice?.number || "Sin numero",
          }
        );
      }
      return info;
    },
    retentionText() {
      if (!this.purchaseInvoice || !this.purchaseInvoice.retention_percent) {
        return "Sin retención";
      }

      if (this.purchaseInvoiceRetentionExpiration) {
        return `${this.purchaseInvoice.retention_percent} % vence ${this.purchaseInvoiceRetentionExpiration}`;
      }

      return `${this.purchaseInvoice.retention_percent} %`;
    },
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    async handleDeleteSaleInvoiceButtonClick() {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: `Se borrará el registro Nº${this.purchaseInvoice.code}.`,
      });

      if (!response.isConfirmed) {
        return;
      }

      this.setLoading(true);
      try {
        await PurchaseInvoicesApi.delete(this.purchaseInvoice?.id);
        await this.$router.push({ name: "purchaseInvoices" });
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>

<style scoped></style>
