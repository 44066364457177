<template>
    <b-card no-body>
      <b-overlay
        :show="loading"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
      >
        <b-card-title
          tag="h1"
          class="m-0 py-1 px-2"
        >
          <b-row
            align-v="center"
            align-h="between"
          >
            <b-col cols="auto">
              <small>{{ offer ? offer.code : '' }}</small>
              <h2 class="font-weight-bold text-capitalize text-dark mb-0">
                {{ offer ? offer.name : '' }}
              </h2>
              <small class="font-weight-bold">{{ offerDateText }}</small>
            </b-col>
            <b-col cols="auto">
              <validation-observer ref="duration-input">
                <validation-provider
                  v-slot="validationContext"
                  name="duracion del contrato"
                  rules="required"
                >
                <b-form-group
                  label="Selecciona la duracion del contrato"
                  label-for="contract-duration"
                >
                  <BaseSelect
                    id="contract-duration"
                    http-method="get"
                    :value="duration"
                    :resource="$data.$constants.RESOURCES.RESOURCE_CONTRACT_DURATION"
                    @input="duration = $event"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </validation-observer>
            </b-col>
            <b-col
              cols="auto"
              class="d-flex justify-content-end"
            >
              <b-button
                variant="primary"
                class="text-nowrap rounded-sm px-2"
                @click="renewContract"
              >
                Renovar contrato
              </b-button>
          </b-col>
        </b-row>
        </b-card-title>
        <hr class="m-0">
        <ProfilePanel :profile="offerProfile" />
      </b-overlay>
    </b-card>
  </template>

  <script>
  import { mapGetters } from 'vuex'
  import JobContractsApi from '@/api/job-contracts-api'
  import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
  import BaseSelect from "@/components/ui/select/BaseSelect.vue";
  import Vue from 'vue'

  export default {
    name: 'JobRenewContractProfileCard',
    components: {
      ProfilePanel,
      BaseSelect,
    },
    props: {
      loading: Boolean,
    },
    data () {
      return {
        duration: null
      }
    },
    computed: {
      ...mapGetters('offer', {
        offer: 'getOffer',
      }),
      offerProfile() {
        return [
          { label: 'División', value: this.offer?.division?.name || 'Sin división' },
          { label: 'Usuario/Comercial', value: this.offer?.user?.name || 'Sin usuario/comercial' },
          { label: 'País', value: this.offer?.project?.country?.name || 'Sin país' },
          { label: 'Provincia', value: this.offer?.project?.province?.name || 'Sin provincia' },
          { label: 'Ciudad', value: this.offer?.project?.population || 'Sin ciudad' },
          { label: 'Cliente', value: this.offer?.client?.name || 'Sin cliente' },
          { label: 'Empresa', value: this.offer?.project?.name || 'Sin empresa' },
        ]
      },
      offerDateText() {
        if (!this.offer || !this.offer.offer_date) {
          return 'Sin fecha de oferta'
        }

        return new Date(this.offer.offer_date).toLocaleDateString()
      },
    },
    methods: {
      async renewContract() {
        this.$emit('setLoading', true)
        const validate = await this.$refs['duration-input'].validate()

        if (!validate) {
          return
        }


        const body = {
          offer_id: this.offer.id,
          contract_duration: this.duration.id,
        }

        try {
          let { job_id } = this.$route.query
          await JobContractsApi.renewOfferContract(job_id, body)
          this.$router.push({ name: 'viewJob', params: { id: job_id } })
        } catch {
          this.$emit('setLoading', false)
          Vue.$toast.error('Ocurrió un error al actualizar la información del job.')
        }
      },
    },
  }
  </script>
