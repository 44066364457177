<template>
  <BaseModal
    v-model="model"
    title="Nueva empresa"
    size="xl"
    :loading="loading"
    @confirm-button-click="handleSaveButtonClick"
  >
    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <ProjectProfileForm
          ref="project-profile-form"
          v-model="project"
        />
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <ProjectResponsibleForm
          ref="project-responsible-form"
          v-model="project"
        />
      </b-col>
    </b-row>
  </BaseModal>
</template>

<script>
import ApiRestService from '@/api/base-api'
import BaseModal from '@/components/ui/modal/BaseModal.vue'
import ProjectResponsibleForm from '@/components/projects/form/ProjectResponsibleForm.vue'
import ProjectProfileForm from '@/components/projects/form/ProjectProfileForm.vue'

export default {
  name: 'ProjectCreationModal',
  components: { ProjectProfileForm, ProjectResponsibleForm, BaseModal },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      project: {},
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    profileForm() {
      return this.$refs['project-profile-form']
    },
    responsibleForm() {
      return this.$refs['project-responsible-form']
    },
  },
  methods: {
    async handleSaveButtonClick() {
      const profileValid = await this.profileForm.validateForm()

      if (!profileValid) {
        return
      }

      this.loading = true
      try {
        const { documentation } = this.profileForm.getDocumentationData()
        const response = await ApiRestService.create({ ...this.project, documentation }, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS)
        const newProject = response.data
        this.$emit('input', false)
        this.profileForm.clearForm()
        this.responsibleForm.clearForm()
        this.$emit('project-created', newProject)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
