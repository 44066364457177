import auth from '@/middleware/auth'
import { RESOURCE_PROJECTS } from '@/shared/constants/resources'

export default [
  {
    path: '/projects',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PROJECTS,
      headerVisible: false,
    },
    name: 'projects',
    component: () => import('@/views/projects/List.vue'),
  },
  {
    path: '/projects/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PROJECTS,
      navActiveLink: 'projects',
      title: 'Empresa',
    },
    name: 'viewProject',
    component: () => import('@/views/projects/View.vue'),
  },
  {
    path: '/projects/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PROJECTS,
      navActiveLink: 'projects',
      title: 'Crear empresa',
    },
    name: 'createProject',
    component: () => import('@/views/projects/Create.vue'),
  },
  {
    path: '/projects/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PROJECTS,
      navActiveLink: 'projects',
      title: 'Editar empresa',
    },
    name: 'editProject',
    component: () => import('@/views/projects/Edit.vue'),
  },
]
