<template>
  <b-row class="pt-4">
    <b-col sm="12" lg="4">
      <b-card>
        <b-table-simple responsive>
          <b-tbody>
            <b-tr v-for="data in tableOne">
              <b-th>{{ data.name }}</b-th>
              <template v-if="!data.edit">
                <b-td>
                  <span v-if="data.cost">
                    {{ data.cost || 0  | numberToLocalString }} {{ data.unit_cost }}
                  </span>
                </b-td>
                <b-td>
                  <span v-if="data.margin">
                    {{ data.margin || 0 | numberToLocalString }} {{ data.unit_margin }}
                  </span>
                </b-td>
              </template>

              <template v-if="data.edit">
                <b-td>
                  <b-form-input
                     v-model="data.cost"
                     style="width: 70px;"
                     type="number"
                    @input="changeData({ [data.key]: data.cost })"
                  />
                </b-td>
              </template>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-col>
    <b-col sm="12" lg="4">
      <b-card>
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>Analisis oferta</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="data in tableTwo">
              <b-th>{{ data.name }}</b-th>

              <b-td>{{ data.value || 0 | numberToLocalString }} {{ data.unit }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-col>
    <b-col sm="12" lg="4">
      <b-card>
        <b-table-simple responsive>
          <b-tr>
            <b-th class="text-right" colspan="8"></b-th>
            <b-th class="text-right" colspan="4">Margen s/venta </b-th>
          </b-tr>
          <b-tr>
            <b-th class="text-right" colspan="8"></b-th>
            <b-th class="d-flex justify-content-end" colspan="4">
              <b-form-input
                v-if="pricesData.costs"
                v-model="pricesData.costs.margin"
                style="width: 70px;"
                type="number"
                @input="changeData({ margin: pricesData.costs.margin })"
              />
            </b-th>
          </b-tr>
        </b-table-simple>
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th colspan="6">Concepto</b-th>
              <b-th colspan="2">UDS.</b-th>
              <b-th colspan="2">Coste/Hora</b-th>
              <b-th colspan="2">Total</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="data in tableTree">
              <b-th colspan="6">{{ data.name }}</b-th>

              <b-td colspan="2">
                <span v-if="!data.edit_uds && data.uds != null">
                  {{ data.uds || 0 | numberToLocalString }}
                </span>
                <template v-if="data.edit_uds">
                  <b-form-input
                    v-model="data.uds"
                    style="width: 70px;"
                    type="number"
                    @input="changeData({ [data.key]: data.uds })"
                  />
                  <small v-if="error[data.key]" class="text-danger">
                    El valor no puede ser 0
                  </small>
                </template>
              </b-td>
              <b-td colspan="2">
                <span v-if="data.cost_hr && !data.edit_cost && isNaN(parseInt(data.cost_hr))">
                  {{ data.cost_hr }}
                </span>
                <span v-else-if="data.cost_hr && !data.edit_cost">
                  {{ data.cost_hr | numberToLocalString }} €
                </span>
                <b-form-select
                  v-if="data.edit_cost"
                  v-model="data.cost_hr"
                  :options="['Si', 'No']"
                  style="width: 70px;"
                  @input="changeData({ [data.key]: data.cost_hr })"
                />
              </b-td>
              <b-td colspan="2">
                <span v-if="data.total != null">
                  {{ data.total || 0 | numberToLocalString }} €
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-col>
    <b-col lg="12">
      <b-card>
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th v-for="header in tableFour.headers" :colspan="header.colspan">
                {{ header.name }}
              </b-th>
            </b-tr>
            <b-tr>
              <b-th v-for="subheader in tableFour.subheaders" :colspan="subheader.colspan">
                {{ subheader.name }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="data in tableFour.data" :class="data.class">
                <b-th colspan="2">{{ data.name }}</b-th>
                <b-td>{{ data.hours || 0 | numberToLocalString }}</b-td>
                <b-td>{{ data.days || 0 | numberToLocalString }}</b-td>
                <b-td>{{ data.execution_cost || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.execution_sale || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.displacement_cost || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.displacement_sale || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.diet_cost || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.diet_sale || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.accommodation_cost || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.accommodation_sale || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.total_cost || 0 | numberToLocalString }} €</b-td>
                <b-td>{{ data.total_sale || 0 | numberToLocalString }} €</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-col>
    <b-col cols="12" class="d-flex justify-content-end">
      <b-btn class="mr-2" :disabled="disabledSave" variant="primary" @click="handleUpdate">
          Guardar
        </b-btn>
      <b-btn variant="primary" @click="overturn">
        Volcar tarifas
      </b-btn>
    </b-col>
  </b-row>
</template>

<script>
import RaterApi from "@/api/rater-api";

export default {
  name: "RaterPricesTable",
  data() {
    return {
      pricesData: {},
      updateData: [],
      error: {},
    };
  },
  computed: {
    tableOne() {
      let data = [];
      if (this.pricesData.costs) {
        const {
          own_labor_cost,
          average_labor_cost,
          travel_cost,
          diet_cost,
          accommodation_cost,
          journey_hours,
          diesel_cost,
          kms_displacement,
          travel_hours,
        } = this.pricesData.costs;

        data = [
          {
            name: "Coste mano de empresa propio",
            cost: own_labor_cost.cost,
            margin: own_labor_cost.with_margin,
            unit_cost: '€',
            unit_margin: '€'
          },
          {
            name: "Coste mano de empresa promedio",
            cost: average_labor_cost.cost,
            margin: average_labor_cost.with_margin,
            unit_cost: '€',
            unit_margin: '€'
          },
          {
            name: "Coste desplazamiento",
            cost: travel_cost.cost,
            margin: travel_cost.with_margin,
            unit_cost: '€',
            unit_margin: '€'
          },
          {
            name: "Coste dieta",
            cost: diet_cost.cost,
            margin: diet_cost.with_margin,
            unit_cost: '€',
            unit_margin: '€'
          },
          {
            name: "Alojamiento",
            cost: accommodation_cost.cost,
            margin: accommodation_cost.with_margin,
            unit_cost: '€',
            unit_margin: '€'
          },
          {
            name: "",
            cost: "",
          },
          {
            name: "Horas efectivas jornada",
            cost: journey_hours,
            key: 'journey_hours',
            edit: true
          },
          {
            name: "Coste gasóleo",
            cost: diesel_cost,
            key: 'diesel_cost',
            edit: true
          },
          {
            name: "KMS. Desplazamiento",
            cost: kms_displacement,
            key: 'kms_displacement',
            edit: true
          },
          {
            name: "Horas Desplazamiento",
            cost: travel_hours,
          },
        ];
      }

      return data;
    },
    tableTwo() {
      let data = [];
      if (this.pricesData.analysis) {
        const {
          sale,
          cost,
          benefit,
          margin,
          secondary_cost,
          days_execution,
          hours_tech,
          real_hour_cost,
        } = this.pricesData.analysis;

        data = [
          {
            name: "Venta preventivo",
            value: sale,
            unit: '€',
          },
          {
            name: "Costes",
            value: cost,
            unit: '€',
          },
          {
            name: "Beneficio",
            value: benefit,
            unit: '€',
          },
          {
            name: "Margen",
            value: margin,
            unit: '%',
          },
          {
            name: "Coste secund/total",
            value: secondary_cost,
            unit: '%',
          },
          {
            name: "Dias ejecucion",
            value: days_execution,
          },
          {
            name: "Horas por tecnico",
            value: hours_tech,
          },
          {
            name: "Coste hora real",
            value: real_hour_cost,
            unit: '€',
          },
        ];
      }
      return data;
    },
    tableTree() {
      let data = [];
      if (this.pricesData.costs) {
        const {
          displacement,
          accommodations,
          hire_technical,
          own_technical,
          travel_diet,
        } = this.pricesData.costs

        data = [
          {
            name: 'Tecnico propio',
            ...own_technical,
            key: 'own_technician',
            edit_uds: true
          },
          {
            name: 'Tecnico contrata',
            ...hire_technical,
            key: 'technician_hires',
            edit_uds: true
          },
          { name: '' },
          { name: 'Desplazamientos', ...displacement },
          { name: '' },
          {
            name: 'Alojamientos',
            ...accommodations,
            key: 'accommodations',
            edit_cost: true,
          },
          { name: '' },
          { name: 'Dietas', ...travel_diet },
        ]
      }

      return data
    },
    tableFour() {
      let data = {
        headers: [
          {
            name: 'Análisis de costes por revisión',
            colspan: 2,
            subheaders: []
          },
          {
            name: 'Total Horas',
            colspan: 1,
          },
          {
            name: 'Total Dias',
            colspan: 1,
          },
          {
            name: 'Ejecucion',
            colspan: 2,
          },
          {
            name: 'Desplazamientos',
            colspan: 2,
          },
          {
            name: 'Dietas',
            colspan: 2,
          },
          {
            name: 'Alojamiento',
            colspan: 2,
          },
          {
            name: 'Totales',
            colspan: 2,
          },
        ],
        subheaders: [
          { name: '', colspan: 2, },
          { name: '' },
          { name: '' },
          { name: 'Coste', colspan: 1, },
          { name: 'Venta', colspan: 1 },
          { name: 'Coste', colspan: 1, },
          { name: 'Venta', colspan: 1 },
          { name: 'Coste', colspan: 1, },
          { name: 'Venta', colspan: 1 },
          { name: 'Coste', colspan: 1, },
          { name: 'Venta', colspan: 1 },
          { name: 'Coste', colspan: 1, },
          { name: 'Venta', colspan: 1 },
        ],
        data: []
      }
      if (this.pricesData.totals) {
        const {
          trimestral,
          semestral,
          trimestral_2,
          anual,
          total,
        } = this.pricesData.totals
        data.data = [
          {
            name: 'Revision trimestral (I) (1ud.)',
            ...trimestral,
          },
          {
            name: 'Revision semestral (1ud.)',
            ...semestral,
          },
          {
            name: 'Revision trimestral (II) (1ud.)',
            ...trimestral_2,
          },
          {
            name: 'Revision anual (1ud.)',
            ...anual,
          },
          {
            name: 'Revisiones mantto. preventivo',
            ...total,
            class: 'text-primary',
          },
        ]
      }
      return data
    },
    disabledSave() {
      return this.updateData.length === 0
    }
  },
  methods: {
    async loadPricesData() {
      const offerId = this.$route.params.id;
      this.$emit("updateLoading", true);
      try {
        const response = await RaterApi.getRates(offerId);
        this.pricesData = response.data;
      } finally {
        this.$emit("updateLoading", false);
      }
    },
    validationForm(dataUpdate) {
      let sum;

      if (Object.keys(dataUpdate)[0] === 'technician_hires') {
        sum = parseInt(dataUpdate.technician_hires || 0) + parseInt(this.pricesData.costs.own_technical.uds)

        if (!sum >= 1 || isNaN(sum)) {
          this.$set(this.error, Object.keys(dataUpdate)[0], true)
          return false
        }
      }
      if (Object.keys(dataUpdate)[0] === 'own_technician') {
        sum = parseInt(dataUpdate.own_technician || 0) + parseInt(this.pricesData.costs.hire_technical.uds)

        if (!sum >= 1 || isNaN(sum)) {
          this.$set(this.error, Object.keys(dataUpdate)[0], true)
          return false
        }
      }

      this.error = {}
      return true

    },
    changeData(dataUpdated) {
      if (!this.validationForm(dataUpdated)) return

      const exist = this.updateData.some((data) => Object.keys(data)[0]  === Object.keys(dataUpdated)[0])

      if(exist) {
        this.updateData = this.updateData.map((data) => {
          if(Object.keys(data)[0]  === Object.keys(dataUpdated)[0] ) {
            data = dataUpdated
          }
          return data
        })
      } else {
        this.updateData.push(dataUpdated)
      }
    },
    async handleUpdate() {
      const offerId = this.$route.params.id;

      this.$emit("updateLoading", true);

      try {
        const updatePromises = this.updateData.map(async (data) => {
          await RaterApi.updateRates(offerId, data);
        })

        await Promise.all(updatePromises);
      } finally {
        this.updateData = []
        this.loadPricesData();
      }
    },
    async overturn () {
      const offerId = this.$route.params.id;

      this.$emit("updateLoading", true);
      try {
        await RaterApi.overturn(offerId);
        this.$router.push({ name: 'viewOffer', params: { id: offerId } })
      } finally {
        this.$emit("updateLoading", false);
      }
    }
  },
  mounted() {
    this.loadPricesData();
  },
};
</script>
