<template>
  <div>
    <BaseTable
      ref="offers-table"
      :columns="columns"
      :totals-configuration="totalsConfiguration"
      :filters="baseFilters"
      :excluded-filters="baseExcludeFilters"
      :resource="$data.$constants.RESOURCES.RESOURCE_OFFERS"
      :resourceStore="'offer'"
      :loading="loading"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearOfferListFilters"
      @row-clicked="$router.push({ name: 'viewOffer', params: { id: $event.id } })"
    >
      <template #cell(client)="data">
        <span> {{ data.item.client.name }} </span>
      </template>
      <template #cell(project)="data">
        <span> {{ data.item.project.name }} </span>
      </template>
      <template #cell(status)="data">
        <StatusBadge :status="data.item.status.name" />
      </template>
      <template #cell(total_cost)="{ value }">
        {{ value | numberToLocalString }} €
      </template>
      <template #cell(total_sale_offer)="{ value }">
        {{ value | numberToLocalString }} €
      </template>
      <template #cell(actions)="data">
        <b-row
          no-gutters
          align-v="center"
          align-h="center"
        >
          <b-col
            cols="auto"
            class="text-left"
          >
            <b-link
              class="d-block text-indigo text-capitalize text-decoration-none"
              @click="handleCheckOfferLinkClick(data.item.id)"
            >
              <u>Revisión oferta</u>
            </b-link>
            <b-link
              class="d-block text-indigo text-capitalize text-decoration-none"
              @click="handleDuplicateLinkClick(data.item.id)"
            >
              <u>Duplicado</u>
            </b-link>
          </b-col>
          <b-col>
            <b-link class="d-inline-block text-indigo">
              <feather-icon
                v-if="data.item.documentation.length"
                v-b-tooltip.hover
                title="Descargar documentación"
                icon="DownloadIcon"
                size="18"
                @click.stop="handleDownloadClick(data.item.documentation)"
              />
            </b-link>
            <b-link
              :to="{ name: 'viewOffer', params: { id: data.item.id} }"
              class="px-1 d-inline-block text-indigo"
            >
              <feather-icon
                v-b-tooltip.hover
                title="Ver"
                icon="EyeIcon"
                size="18"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_OFFERS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              class="d-inline-block text-danger"
              @click="deleteOffer(data.item.id, data.item.name)"
            >
              <feather-icon
                v-b-tooltip.hover
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </b-link>
          </b-col>
        </b-row>
      </template>
    </BaseTable>
    <OfferListFilters
      ref="offer-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('offer/setFilters', $event)"
    />
  </div>
</template>

<script>
import BaseApi from '@/api/base-api'
import OfferListFilters from '@/components/offers/filters/OfferListFilters.vue'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import OffersApi from '@/api/offers-api'
import { mapGetters } from 'vuex'

export default {
  name: 'OffersTable',
  components: { StatusBadge, BaseTable, OfferListFilters },
  props: {
    clientId: {
      type: String,
      default: null,
    },
    projectId: {
      type: String,
      default: null,
    },
    excludedFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filtersVisible: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'offer/getFilters',
    }),
    baseExcludeFilters() {
      const result = this.excludedFilters
      if (this.clientId) {
        result.push('client_id')
      }

      if (this.projectId) {
        result.push('project_id')
      }

      return result
    },
    baseFilters() {
      return { client_id: this.clientId, project_id: this.projectId, ...this.filters }
    },
    totalsConfiguration() {
      let firstColspan = 7
      if (this.clientId) {
        firstColspan -= 1
      }

      if (this.projectId) {
        firstColspan -= 1
      }

      return [
        { colspan: firstColspan },
        { label: 'TOTAL COSTE', key: 'total_cost', unit: '€' },
        { label: 'TOTAL VALOR VENTA', key: 'total_sale_offer', unit: '€' }
      ]
    },
    columns() {
      const result = [
        {
          label: 'FECHA',
          key: 'offer_date',
          sortable: true,
        },
        {
          label: 'CÓDIGO',
          key: 'code',
          sortable: true,
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'NOMBRE',
          key: 'name',
          sortable: true,
        },
      ]
      if (!this.clientId) {
        result.push(
          {
            label: 'CLIENTE',
            key: 'client',
            sortable: true,
          },
        )
      }

      if (!this.projectId) {
        result.push({
          label: 'EMPRESA',
          key: 'project',
          sortable: true,
        })
      }

      return result.concat([
        {
          label: 'ESTADO',
          key: 'status',
          sortable: true,
        },
        {
          label: 'COSTES',
          key: 'total_cost',
          sortable: true,
          thStyle: { 'min-width': '130px' },
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'VALOR VENTA',
          key: 'total_sale_offer',
          sortable: true,
          thStyle: { 'min-width': '130px' },
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'ACCIONES',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '230px' },
        },
      ])
    },
  },
  methods: {
    handleClearOfferListFilters() {
      this.$store.commit('offer/setFilters', {})
      this.$refs['offer-list-filters'].clearFilters()
    },
    async handleDuplicateLinkClick(offerId) {
      this.loading = true
      try {
        const response = await OffersApi.duplicate(offerId)
        await this.$router.push({ name: 'viewOffer', params: { id: response.data.id } })
      } finally {
        this.loading = false
      }
    },
    async handleDownloadClick(documents) {
      const { id, name } = documents[0]
      this.loading = true
      try {
        await BaseApi.downloadArchive(id, name)
      } finally {
        this.loading = false
      }
    },
    async handleCheckOfferLinkClick(offerId) {
      this.loading = true
      try {
        const response = await OffersApi.check(offerId)
        await this.$router.push({ name: 'viewOffer', params: { id: response.data.id } })
      } finally {
        this.loading = false
      }
    },
    async deleteOffer(offerId, name) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${name}`,
      })

      if (!response.isConfirmed) {
        return
      }

      try {
        this.loading = true
        await OffersApi.delete(offerId)
        await this.$refs['offers-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
