<template>
  <JobLinesCard
    :job-id="jobId"
    :job-lines="jobExpectedCostLines"
    :totals="jobExpectedCostLinesTotals"
    :totals-configuration="totalsConfiguration"
    :loading="loading"
    title="costes previstos"
    add-button-text="+ Nuevo coste"
    @create-line="handleCreateLine"
  >
    <template v-slot:default="{ linesData }">
      <JobExpectedCostLinesTable
        :ref="jobExpectedCostTableReference + linesData.id"
        :lines-data="linesData"
      />
    </template>
  </JobLinesCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import JobExpectedCostLinesApi from '@/api/job-expected-cost-lines-api'
import JobLinesCard from '@/components/jobs/card/JobLinesCard.vue'
import JobExpectedCostLinesTable from '@/components/jobs/table/JobExpectedCostLinesTable.vue'

const JOB_EXPECTED_COST_TABLE_REFERENCE = 'job-expected-cost-table-'

export default {
  name: 'JobExpectedCostLinesCard',
  components: { JobExpectedCostLinesTable, JobLinesCard },
  props: {
    jobId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      totalsConfiguration: [
        { label: 'prevision inicial', key: 'total_prevision' },
        { label: 'previsión viva', key: 'live_prevision' },
        { label: 'consumido', key: 'total_order' },
        { label: 'pendiente', key: 'total_pendent' },
      ],
      jobExpectedCostTableReference: JOB_EXPECTED_COST_TABLE_REFERENCE,
    }
  },
  computed: {
    ...mapGetters('job', {
      jobExpectedCostLines: 'getJobExpectedCostLines',
      jobExpectedCostLinesTotals: 'getJobExpectedCostLinesTotals',
      contractSelected: 'getContractSelected',
      isMaintenance: 'getIsMaintenance',
    }),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('job', ['loadJobExpectedCostLines', 'loadJobEconomicState', 'setExpectedCostLineRowVariant']),
    async fetchData() {
      if (!this.jobId) {
        return
      }

      this.loading = true
      try {
        await this.loadJobExpectedCostLines(this.jobId)
      } finally {
        this.loading = false
      }
    },
    async handleCreateLine(line) {
      if (!this.jobId) {
        return
      }


      let params = line

      if(this.isMaintenance) {
        params = { ...line, job_contract_id: this.contractSelected(this.jobId).contract.id }
      }


      this.loading = true
      try {
        const response = await JobExpectedCostLinesApi.create(params)
        const newLine = response.data
        this.setCollapseLine(newLine.category_group.id, true)
        await Promise.all([this.loadJobExpectedCostLines(this.jobId), this.loadJobEconomicState(this.jobId)])
        this.focusExpectedCostLine(newLine)
      } finally {
        this.loading = false
      }
    },
    focusExpectedCostLine(line) {
      this.setCollapseLine(line.category_group.id, true)
      this.setExpectedCostLineRowVariant({
        categoryGroupId: line.category_group.id, lineId: line.id, rowVariant: 'warning',
      })
      this.collapseAllExcept(line.category_group.id)

      const lines = this.jobExpectedCostLines.find(({ id }) => id === line.category_group.id).lines
      const index = lines?.findIndex(({ id }) => id === line.id)

      this.$store.commit('job/setRowsEditionEnabled', { index, value: true })
      document.getElementById(`job-expected-cost-lines-${line.category_group.id}`).scrollIntoView({
        behavior: 'smooth',
      })
    },
    collapseAllExcept(excludedLineReference) {
      const lines = this.jobExpectedCostLines.filter(jobExpectedCostLine => jobExpectedCostLine.id !== excludedLineReference)
      lines.forEach(line => {
        this.setCollapseLine(line.id, false)
      })
    },
    setCollapseLine(lineReference, collapse) {
      const jobExpectedCost = this.$refs[this.jobExpectedCostTableReference + lineReference]
      if (jobExpectedCost) {
        jobExpectedCost.setCollapse(collapse)
      }
    },
  },
}
</script>

<style scoped>
</style>
